<template>
  <div>
    <create-display-error :validation="validation"/>

    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('job.create_edit.section_one.title') }}</h3>
      <p class="d-flex align-items-center">{{ $t('job.create_edit.section_one.title_description') }}</p>
      <div>
        <input type="text" class="form-control" v-model="jobData.title" v-bind:class="{ 'border-danger': !isValid('title') }" required>
      </div>
    </div>

    <div class="mb-4">
      <h3 class="text-left d-flex justify-content-between font-weight-bold">{{ $t('job.create_edit.section_one.description') }}</h3>
      <p class="d-flex align-items-center">{{ $t('job.create_edit.section_one.description_description') }}</p>
      <div>
        <vue-editor name="textarea" :editorToolbar="[['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }]]" v-model="jobData.description"
                    v-bind:class="{ 'border border-danger': !isValid('description') }" required/>
      </div>
    </div>

    <div class="text-right pt-2 pb-2 d-flex flex-md-row-reverse flex-column" v-if="displayButtons">
      <button class="btn btn-primary" @click="onSubmit">{{ $t('job.create_edit.button.save') }} <b-spinner small label="Small Spinner" class="ml-2" v-if="saving"/></button>
      <router-link class="btn btn-link text-dark" :to="{'name': 'job-view'}">{{ $t('job.create_edit.button.back') }}</router-link>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import CreateDisplayError from './CreateDisplayError'

export default {
  name: 'CreateEditSection1',
  components: { CreateDisplayError, VueEditor },
  props: {
    job: {
      type: Object,
      required: false,
      default () {
        return { title: null, description: null }
      }
    },
    validationFailed: {
      type: Object,
      required: false,
      default () {
        return { messages: [], fields: [] }
      }
    },
    displayButtons: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      validation: {
        messages: [],
        fields: []
      },
      jobData: {
        title: this.job.title,
        description: this.job.description
      },
      saving: false
    }
  },
  watch: {
    'validationFailed': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.validation = newValue
        this.saving = false
      }
    }
  },
  mounted () {
    this.$tracker.createJobSection(this.$route.name === 'job-edit' ? 'editJob1' : 'createJob1')
  },
  methods: {
    onSubmit () {
      this.validation = { messages: [], fields: [] }

      if (this.jobData.title === null || this.jobData.title.length < 10) {
        this.validation.fields.push('title')
        this.validation.messages.push(this.$t('job.create_edit.section_one.validation.title_short'))
      } else if (this.jobData.title.length > 195) {
        this.validation.fields.push('title')
        this.validation.messages.push(this.$t('job.create_edit.section_one.validation.title_long'))
      }

      if (this.jobData.description === null || this.jobData.description.length < 10) {
        this.validation.fields.push('description')
        this.validation.messages.push(this.$t('job.create_edit.section_one.validation.description_short'))
      }

      if (this.validation.messages.length < 1) {
        this.jobData.title = this.$sanitize(this.jobData.title)
        this.jobData.description = this.$sanitize(this.jobData.description, {
          allowedTags: ['strong', 'em', 'p', 'u', 'ul', 'ol', 'li', 'em', 'i']
        })
        this.$emit('job-create-edit-save', this.jobData)
        this.saving = true
      } else {
        window.scrollTo(0, 0)
      }
    },
    isValid (value) {
      if (this.validation.messages.length > 0) {
        return this.validation.fields.indexOf(value) === -1
      }
      return true
    }
  }
}
</script>
